import { useEffect, useState } from "preact/hooks"
import { Notification } from "./Notification.tsx"
import { store } from "@/stores/notifications-store.ts"

export const Notifications = () => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    store.subscribe((value) => {
      setNotifications(value);
    });
  }, [])

  return (
    <>
      {notifications.map((notification) => (<Notification notification={notification} />))}
    </>
  )
}
